import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import { Row, Col } from 'react-flexbox-grid';
import Navigation from './headerNav';
import Logo from './logo';
import Grain from './grain';
import * as Fancy from '../utils/fancy';
import Topo from './topo';
import Social from '../images/Spinturnix.com-Social.jpg';
import SocialSmall from '../images/social-small.jpg';

// Styles
import '../css/typography.css';
import '../css/style.scss';
import '../css/animations.scss';
import '../css/logo.scss';

// Special Console Message
console.log(
  '%c Design + Development by Spinturnix ',
  'background: #001990; color: white; font-family:"Josefin Sans"; font-size: 18px; line-height: 30px; padding: 5px; display: block;'
);

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      width: undefined,
      height: undefined,
    };
  }

  componentWillMount() {
    this.updateDimensions();
    this.isLoading();
  }

  componentDidMount() {
    Fancy.microSmooth();

    function fullStory() {
      window._fs_debug = false;
      window._fs_host = 'fullstory.com';
      window._fs_org = '33XQW';
      window._fs_namespace = 'FullStory';
      (function(m, n, e, t, l, o, g, y) {
        if (e in m) {
          if (m.console && m.console.log) {
            m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
          }
          return;
        }
        g = m[e] = function(a, b) {
          g.q ? g.q.push([a, b]) : g._api(a, b);
        };
        g.q = [];
        o = n.createElement(t);
        o.async = 1;
        o.src = `https://${_fs_host}/s/fs.js`;
        y = n.getElementsByTagName(t)[0];
        y.parentNode.insertBefore(o, y);
        g.identify = function(i, v) {
          g(l, { uid: i });
          if (v) g(l, v);
        };
        g.setUserVars = function(v) {
          g(l, v);
        };
        y = 'rec';
        g.shutdown = function(i, v) {
          g(y, !1);
        };
        g.restart = function(i, v) {
          g(y, !0);
        };
        g.identifyAccount = function(i, v) {
          o = 'account';
          v = v || {};
          v.acctId = i;
          g(o, v);
        };
        g.clearUserCookie = function() {};
      })(window, document, window._fs_namespace, 'script', 'user');
    }
    fullStory();

    window.addEventListener('resize', this.updateDimensions);

    const el = document.querySelector('.grain');
    const grain = new Grain(el);

    if (this.state.width > 500) {
      Fancy.dot(); // cursor
    }

    Fancy.upDown(); // auto-hide nav
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    if (typeof window !== 'undefined') {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
  };

  isLoading = () => {
    if (typeof document !== 'undefined') {
      document.body.classList.add('loading');
    }
  };

  render() {
    return (
      <div className="layout-wrap">
        <Helmet
          title="Spinturnix | Website Design + Development"
          meta={[
            { charSet: 'utf-8' },
            { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
            { name: 'description', content: 'Website Design and Development' },
            { name: 'keywords', content: 'Website Design, Website Development, WordPress, Shopify, Expert' },
            { name: 'theme-color', content: '#11111C' },
            { name: 'image', content: Social },
            { itemprop: 'name', content: 'Spinturnix Website Design + Development' },
            { itemprop: 'image', content: Social },
            { name: 'twitter:title', content: 'Spinturnix Website Design + Development' },
            { name: 'twitter:site', content: '@SpinturnixInc' },
            { name: 'twitter:image:src', content: SocialSmall },
            { name: 'og:title', content: 'Spinturnix Website Design + Development' },
            { name: 'og:image', content: SocialSmall },
            { name: 'og:url', content: 'https://spinturnix.com' },
            { name: 'og:site_name', content: 'Spinturnix Website Design + Development' },
            { name: 'og:type', content: 'website' },
          ]}
        >
          <script src="https://embed.small.chat/T1FMMD5MXG68U9D4MN.js" async />
          <html lang="en" />
        </Helmet>

        <div className="header" style={{ margin: '0 auto', padding: '1.45rem 1.0875rem' }}>
          <Link to="/">
            <Logo />
          </Link>
          <Navigation />
        </div>

        <div>{this.props.children}</div>

        <Topo />

        <canvas className="grain" />

        <section id="start" className="full-section">
          <Row middle="xs" center="xs">
            <Col xs={10} md={6}>
              <h2 className="section-heading">Let's Work Together</h2>
              <p>
                Getting started is easy, simply click the message button at the bottom right of the page. Or email me
                directly at:
              </p>
              <h3>
                <a href="mailto:trevor@spinturnix.com">Trevor@Spinturnix.com</a>
              </h3>
            </Col>
          </Row>
        </section>

        <footer className="footer">
          <Row middle="xs" center="xs">
            <Col xs={10}>Copyright 2017 - {new Date().getFullYear()} Spinturnix Inc. | All Rights Reserved</Col>
          </Row>
        </footer>

        <div id="cursor" />
        <div id="cursor-bg" />
      </div>
    );
  }
}

export default Layout;
