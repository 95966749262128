import React from "react"

export default class Logo extends React.Component {
  render(){
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="57" viewBox="0 0 221.72 123.8">
        <path className="logo-path" d="M220.94.07,111.87,60.18a2.1,2.1,0,0,1-2,0L.78.07A.52.52,0,0,0,.05.75l18.66,39.6a2.08,2.08,0,0,1-.12,2C7.25,61,12.24,86.18,31.32,100.44A48,48,0,0,0,50.11,109c12.79,2.72,23.25.15,35.65-7.68a2.11,2.11,0,0,1,2.71.38l19.07,21.92a.52.52,0,0,0,.9-.49L99.85,92.8a2.07,2.07,0,0,0-.65-1l-16.45-14a2,2,0,0,0-2.27-.2C78,79,75,80.73,72.06,82.21a33.5,33.5,0,0,1-13,3.85A27.68,27.68,0,0,1,35,42.71a2.12,2.12,0,0,0-.11-2.61l-11-12.58L109.7,84.08a2.1,2.1,0,0,0,2.31,0l85.77-56.56-11,12.58a2.12,2.12,0,0,0-.11,2.61,27.68,27.68,0,0,1-24.1,43.35,33.51,33.51,0,0,1-13-3.85c-2.89-1.48-5.92-3.21-8.42-4.67a2,2,0,0,0-2.27.2l-16.45,14a2.07,2.07,0,0,0-.65,1l-8.59,30.33a.52.52,0,0,0,.9.49l19.07-21.92a2.11,2.11,0,0,1,2.71-.38c12.41,7.84,22.86,10.4,35.65,7.68a48,48,0,0,0,18.79-8.57C209.48,86.18,214.47,61,203.12,42.32a2.08,2.08,0,0,1-.12-2L221.67.75A.52.52,0,0,0,220.94.07Z"/>
      </svg>
    )
  }
}
