import React from 'react'

export default function Topo() {
  return (
    <div id="topo-wrap">
    <svg version="1.1" id="topo" x="0px" y="0px" viewBox="0 0 1920 1080" preserveAspectRatio="xMinYMax slice">
      <rect id="bg" x="0.1" y="-0.1" className="st0" width="1920" height="1080" />
      <g id="thick">
        <path className="st1" d="M-1.9,273.6c15.3-11.3,29.6-24.2,39.9-40.1c15.4-23.9,7.9-55.7,22.6-81.3c7.3-12.6,21.5-19.2,34.1-26.5
            s25.6-17.7,26.9-32.3c1.6-17.9-14.8-31.4-25.8-45.7C85.6,34.3,78.9,16.2,77.2-2.1"/>
        <path className="st1" d="M-1.9,737c5,6.4,9.6,12.9,14,19.7c13.3,20.9,26.4,45.3,53.5,49.3s52.4,18.5,77.4,29.2
            c29.7,12.7,59.6,25.5,91,32.8c9,2.1,18.2,3.7,26.6,7.5c22.7,10.1,42.8,41,43.6,65.7c0.2,4.8-0.3,9.8,0.6,14.5
            c1.7,8.6,7.9,15.5,11.5,23.4c4.7,10.4,4.8,22.4,9,33c3.5,8.9,9.5,16.2,14.7,24.1c4.7,7.1,7.7,14.2,13.7,20.4
            c9.5,9.6,21.4,13.6,32.2,21.3c1.7,1.2,3.6,2.6,5.4,4"/>
        <path className="st1" d="M1922.1,379.2c-7-5-15.4-9.8-24.5-17c-9.6-7.6-18.9-15.9-26-25.9c-21.9-30.5-20.9-72.4-9-108
            c6-17.7,12.3-39,6.3-57.5c-4.8-14.7-21.5-26.6-35.7-20.5c-9.3,4-14.3,14-20.7,22c-18.3,22.9-49.3,29.8-75.9,42.1
            c-47.4,21.8-84.7,64.6-99.7,114.6c-9.4,31.5-1.3,58.9-2.6,90.6c-3.3,77.7-81.4,126.4-134.7,183.1c-37.2,39.5-63.3,86.1-79,137.5
            c-1.8,5.8-4.2,12.3-9.8,14.5c-5.3,2-11.3-0.8-15.2-5c-3.9-4.2-6.3-9.4-9.6-14c-9.6-13.4-26.4-20.3-42.8-21
            c-2.4-0.1-5.1-0.3-6.6-2.1c-0.7-1.1-1.1-2.4-1.2-3.7c-4.8-36.8,28.5-85.8,0.6-118.6c-6.6-7.7-15.2-13.4-21.7-21
            c-13.8-16.2-16.7-38.7-24.6-58.4c-9.4-23.5-26-43.4-35.7-66.7c-12.5-30.2-38.1-42.8-62.9-63.9c-27.7-23.5-57.3-45.3-90.8-59.9
            c-31.1-13.5-50.9-40.3-76.5-61.9c-25.5-21.7-52.7-44-85.8-48.9c-10.7-1.6-21.6-1.2-32.5-1.6c-22.6-0.9-44.9-5.5-66.1-13.5
            c-42.1-15.9-52.8-58.4-20.3-90.6c28-27.7,72-39.9,90-74.9c4.2-8.2,6.7-17.1,8.9-26.1c0.4-1.6,0.8-3.3,1.2-4.9"/>
        <path className="st1" d="M326.3-2.1l17.5,46.3c2.6,6.8,5.2,13.7,6.7,20.8c2.4,11.4,2.1,23.2-1,34.5c-1.2,4.2-2.8,8.3-5.7,11.4
            c-3.4,3.6-8,5.4-12.4,7.7c-33.4,17.7-42.7,61.9-60.8,92.2c-10.3,17.3-22.8,31.8-35.9,47c-26.8,31.1-33.4,76.1-61.1,106.4
            c-12,13.1-27.3,22.7-41.7,33.2s-28.4,22.5-36.1,38.6c-8.1,17-5.5,34.5,4.9,50.2c8.1,12.3,19.8,21.8,32.3,29.7
            c7.5,4.8,15.3,9,22.2,14.6c5.7,4.6,10.6,10,15.7,15.1c6.6,6.7,13.6,13.2,20.5,19.6c13.9,12.9,26.5,31.8,43,40.9
            c20.7,11.5,42.9,16.7,61.9,31.9c7.2,5.7,13.8,12.2,21,17.9c14.1,11,30.5,18.7,45.7,28.2c23,14.3,43.2,32.7,63.1,51.1
            c25.6,23.6,51.3,47.9,69.3,77.7c14.7,24.3,23.6,51.6,32.5,78.6c5,15.4,10.1,30.9,13.1,46.8c1.9,10,3,20.2,5.8,29.9
            s7.3,18.8,11.7,27.9c13.9,28.3,27.6,55.9,44.8,82.3c0.8,1.2,1.5,2.4,2.3,3.5"/>
        <path className="st1" d="M987.2,1081.9c-36-41.8-60.8-93.4-69.8-147.8c-4.1-24.7-5.3-50-13.1-73.7c-9.5-28.5-28.3-53.5-35.4-82.7
            c-2-8.1-3.1-16.4-6.3-24.1c-13.9-32.3-57.7-37.2-84.2-60.4c-14.2-12.5-23-30.1-37-42.9c-20.8-19.1-52.8-25-79-14.5
            c-5.7,2.3-11.3,5.4-14.5,10.5c-3.8,6-3.8,13.7-3,20.9c3.4,30.5,17.3,58.9,39.2,80.3c5.1,5,10.8,9.7,13.4,16.3
            c2.3,5.9,2,12.4,1.8,18.7c-0.8,46.3,15.5,91.5,38.2,131.8s51.7,76.7,79,114.1c11.7,16.1,23.3,32.7,30.4,51.3
            c0.3,0.7,0.5,1.4,0.8,2.1"/>
        <path className="st1" d="M1357.1,1081.9c-0.5-2.9-1.1-5.8-1.6-8.6c-19.5-97.3-89.6-183-181.2-221.4c-11.9-5-24.4-9.4-33.7-18.3
            c-8.1-7.8-13-18.4-17.2-28.9c-15.8-40.1-7-83.4-27-123.4c-3.5-7.1-7.6-13.8-11-21c-4.9-10.4-8.2-21.4-12.3-32.1
            c-8.1-21.1-19.1-40.8-32.9-58.7c-2.2-2.9-4.6-5.8-7.8-7.6c-4-2.3-8.9-2.6-13.5-3c-26-1.9-58.6-5.3-82.6-15.6
            c-14.6-6.3-27-17.8-33.4-32.5c-3.9-8.9-5.7-18.6-8-28.1c-5.8-24-15.3-46.9-27.9-68.1c-3.6-6-7.5-11.9-13.2-15.8s-12.4-5.4-18.6-8.4
            c-8.7-4.2-15.8-11-24.2-15.6c-10.4-5.7-22.3-8-34.1-9.4c-48.8-5.7-102,1.6-143.6-24.6c-28.3-17.8-46.7-50.5-47.2-84
            s15.5-67.1,7.2-99.5c-2.7-10.4-7.8-20.1-11-30.4c-5.8-18.5,0.5-30.3,8.8-46.3c13.9-26.9,26.3-54.5,37.7-82.6"/>
        <path className="st1" d="M1532,1081.9c36.6-28.7,33.3-84.5,41.4-126c5-25.8,13.5-51.2,34.8-67.8c26-20.3,27-44.4,33.7-75.8
            c4.1-19,11.6-37.1,22-53.5c3.2-5,7-10.2,12.6-12.4c33.3-13.1,63.6,9.4,54.6,43c-10.8,40.2-34,70.8-36.2,114.4
            c-0.8,15.8,0.2,31.8-0.3,47.6c-0.7,20.6-11.1,43.6-10,63.4c0.9,15.4,14.2,33.3,19.6,48c2.3,6.3,4.4,12.7,6.3,19.1"/>
        <path className="st1" d="M396.4,458.6c-4.9-11-13.9-17.4-15.5-29.6c-1.4-10.5-5.1-20.7-10.9-29.5c-0.7-1.2-1.6-2.3-2.6-3.3
            c-2.2-1.8-5-2.8-7.9-2.9c-1.6-0.1-3.2-0.1-4.7-0.1c-2.9,0-5.9,0.1-8.4,1.5c-6,3.2-8.4,13.2-12.4,18.3c-6.6,8.2-12.6,16.9-17.9,26
            c-5.4,9.1-10.4,18.7-11.7,29.2s2.1,22.1,10.7,28.2c7.6,5.3,17.7,5.5,26.7,3.5s17.5-6,26.5-8.3c10.9-2.8,24.2-4.5,29-14.6
            C400.1,471.3,399,464.4,396.4,458.6z"/>
        <path className="st1" d="M1179.3-2.1c1.6,6.8,5.1,13,10,18c17,17,44.7,13.9,68.3,18.6c33.3,6.6,62.1,32.5,72.3,64.9
            c6.3,20,5.8,41.5,10.8,61.8c4.3,17.8,14.6,35.4,25.7,50.1c12.3,16.4,29.1,29.1,46.5,39.6c2.1,1.3,4.4,2.5,6.8,2.4
            c3.4-0.2,6.1-3.2,7-6.5s0.5-6.7,0.1-10.1c-1.9-15-2-31.3,1.4-45.9c3.8-16.3,9.9-32.3,19.7-46c22-30.8,59-46.2,91.2-66.1
            c35-21.7,64.7-49.4,90.6-80.7"/>
      </g>
      <g id="thin">
        <path className="st2" d="M124.2-2.1c0.6,9.6,3.1,19.2,5.6,28.6c1.7,6.5,3.6,13.3,8.2,18.3c2.8,3,6.4,5.2,9.7,7.6
            c18.8,14,27.2,42.1,15.7,62.6c-16.3,29.2-61.3,32.2-77.4,61.5c-6.1,11.1-7.2,24.8-11.5,36.7l-16.3,46c-3.8,10.8-7.9,22-16.2,30
            c-5,4.8-11.3,8.1-17.4,11.2c-8.9,4.5-17.8,9-26.5,13.6"/>
        <path className="st2" d="M-1.9,676.6c1.7,1.1,3.3,2.3,5,3.4c3.7,2.7,7.1,5.6,10.6,8.5l8.3,7c15.4,13,31.2,25.7,46.5,38.6
            c13.3,11.2,21.4,27.6,36,37.8c11.7,8.1,25.6,12,39.1,16.3c41.1,13,76.4,33.3,120.4,38.5c4.1,0.5,8.2,0.9,11.9,2.7
            c12.5,6,13.7,23.3,21.6,34.7c4,5.8,9.9,10,15.6,14.1l30.1,21.6c3.1,2.1,6,4.5,8.5,7.3c2.6,3.4,4.4,7.4,5.4,11.6
            c3.8,15.6-0.5,31.8-3.6,47.5s-4.8,33.1,3.5,46.8c4.1,6.7,10.2,11.9,16.2,16.9c0,5.6,30.2,25.5,35.2,29.7c8.9,7.5,18,14.7,26.6,22.5
            "/>
        <path className="st2" d="M-1.9,628.1c11.6,7.6,23.7,14.7,34.7,22.8c28.3,21,55.7,47.3,80.3,72.5c23.6,24.1,59.6,31.9,90.5,43.3
            c8.7,3.2,17.5,6.4,26.5,8.5c24.2,5.5,49.6,2.6,73.9,7.3c9,1.7,18.3,4.7,24.8,11.2c10.3,10.3,11.5,26.6,19.1,39
            c7.1,11.6,19.3,19,27.4,29.9c9.7,13.1,12.7,29.8,15.4,45.9c3.1,18.8,6.3,37.5,9.4,56.3c1.9,11.3,3.8,22.7,8.5,33.1
            c4.8,10.8,12.3,20.1,19.8,29.2c5.3,6.5,10.6,13.1,17.3,18.1s14.5,8.4,21.7,12.8c10.1,6.4,19,14.5,26.4,23.9"/>
        <path className="st2" d="M179-2.1c-0.4,5.2,0.1,10.5,1.4,15.6c2.5,9,8.1,16.8,13.6,24.4l19.4,26.9c5.5,7.6,11.2,15.9,10.8,25.2
            c-0.4,10.3-8.2,18.7-11.9,28.3c-2.6,6.6-3.3,14.1-7.5,19.7c-4.9,6.6-12.5,8.1-18.9,12.5c-5.9,4.1-10,9.5-15.1,14.6
            c-14.8,14.8-30,28.5-42,45.8c-27.4,39.5-37.8,90.9-73.7,122.8c-16.3,14.5-36.1,23.4-56.9,30.5"/>
        <path className="st2" d="M238.4-2.1c0.6,6.7,2.5,13.2,5.4,19.3c4.3,8.8,10.5,16.8,12.9,26.3c2.1,8.3,1.2,16.9,1,25.4
            c-0.6,30-12.7,54-28.3,79.6c-3.5,6-7.5,11.7-12,17.1c-5.9,6.9-13.1,12.5-20.2,18.1c-43.4,34.3-69.5,95.3-96.6,143
            c-3.9,6.8-7.8,13.7-13.2,19.5c-5.8,6.3-13,11-20.1,15.7C46.1,376,23.1,390.6-1.9,393"/>
        <path className="st2" d="M-1.9,522.6L26,568.7c5,8.3,10.1,16.7,17.3,23.1c7.4,6.6,16.7,10.8,24.8,16.6c16.2,11.6,24.8,29.5,39.6,42.3
            c7.7,6.6,16.5,11.8,25.3,17c42.3,25.1,85.4,52.2,132.2,68.2c27.1,9.3,57.2,9.6,82.5,23.1c22.3,11.9,29.1,29.7,42.6,48.9
            c3,4.3,7.1,7.7,11.1,11l34.2,28.1c4,3.3,8,6.7,10.2,11.3c2,4.5,2,9.5,2,14.4c-0.1,16-3.4,35.9,0,51.6c7.8,35.4,29.5,64.7,47.7,95.2
            c11.8,19.8,18.4,41.9,28.6,62.5"/>
        <path className="st2" d="M273-2.1c-1.1,10.6,0.3,20.8,7.1,28.8c4.8,5.5,11.6,9.1,16.1,14.8s6.2,13.5,7,20.8c2.1,20-2,42.2-15.1,58
            c-5.7,6.9-13.1,10.6-18.6,17.6c-8.6,11-14.2,22.5-19.5,35.1c-5.7,13.6-15.4,24.5-24.3,36.2c-9.5,12.4-19,24.9-27.4,38.1
            c-4.3,6.5-8.1,13.4-11.2,20.6c-2.6,6-4.5,12.3-7.1,18.3c-3.8,8.6-9.1,16.5-14.4,24.3c-13.6,20.1-27.4,40.6-46.1,56.2
            c-6.9,5.6-14.1,10.8-21.6,15.7l-40.7,27.5c-14.5,9.8-44.3,25.7-32.3,46.5c4.3,7.5,11.5,12.7,17.8,18.6c7.5,7.1,13.9,15.2,18.9,24.3
            c4.2,7.5,7.5,15.8,13.5,22c4,4.1,8.9,7.2,13.6,10.5c26.3,18.7,41.2,44.2,62.9,66.9c12.4,13,24.3,23.8,35.2,37.9
            c12,15.6,33.2,22.9,50.8,30.3c19.6,8.2,38.9,17,58.3,25.8l72,32.6c5.5,2.5,11.2,5.1,15.6,9.3c8.4,8,10.6,20.4,15.8,30.7
            c14.6,28.8,50.9,39.7,70.3,65.6c11.6,15.5,16,34.8,18.2,53.6c2.3,19.1,8.3,34.2,14.2,52.6c12.5,39.1,22.4,79.7,42.9,115.5
            c2.4,4.2,5,8.4,7.7,12.5c3.7,5.7,7.7,11.3,11.7,16.8"/>
        <path className="st2" d="M367.3-2.1c9.5,29.9,19.8,59.8,14.7,90.1c-8,47.8-50.6,80.6-79.2,119.8c-44.1,60.6-56.9,141.9-109.6,195.1
            c-19.9,20-47,40.8-43.4,68.7c1.9,14.7,12.3,26.7,22.9,37c24.1,23.3,51.9,36.9,78.8,55.8c11.2,7.9,20.4,17.2,33,23.7
            c14.4,7.5,29.7,13.3,44.4,20.1c39,18.2,73.8,44.2,108.2,69.9c12.3,9.2,24.6,18.5,34.6,30.1c8.6,10,15.2,21.6,21.6,33.1
            c25.1,44.6,49.2,89.6,72.5,135.2c5.9,11.6,11.8,23.3,15.7,35.7c3.2,9.9,5,20.2,7.3,30.4c10.8,48.7,29.4,94.6,51.1,139.2"/>
        <path className="st2" d="M399.7-2.1c11.1,20.2,23.5,40.5,28,62.5c8.4,41.5-13,83.5-39.8,116.2s-59.9,60.7-82,96.9
            c-31.5,51.7-42.3,121.6-95,151.4c-8.5,4.8-17.9,8.5-24.3,15.9c-9.4,11-10.9,30.8-1.6,42.3c10.6,13.1,19.2,26.2,34.1,35
            c31.3,18.6,63.8,35.2,97.3,49.5c31.6,13.5,64.7,22.5,92.5,43.3c17.6,13.3,31.8,30.5,45.9,47.5L539,760.6
            c10.7,13,21.5,26.2,28.8,41.4c10.8,22.4,13.3,47.7,19.7,71.7c20.2,76.3,78.6,139.2,115.6,208.2"/>
        <path className="st2" d="M771.7,1081.9c-1.2-3.7-2.6-7.4-4-11c-6-14.9-12.6-30-23.5-41.8c-6.8-7.3-15-13.1-22.7-19.5
            c-26.3-22.1-40.6-51.5-62.4-77.1c-6.9-8.1-14.9-14.2-19.8-23.9c-7.9-15.5-11.4-34.3-15.5-51c-8.4-34.3-14.8-70.1-33.4-100.2
            c-26.6-43-76-70.6-93.7-117.9c-10.3-27.6-11.9-63.8-38.4-76.6c-13-6.3-28.8-4-42.8-6.1c-16.8-2.6-33.5-5.3-50.2-8.6
            c-33.2-6.5-66.1-15.1-97.2-28.5c-13.4-5.8-27.3-13.3-33.8-26.4c-6.9-14-3.4-31.1,3.9-44.9c8-15.3,20.3-27.7,29.7-41.8
            c11.1-16.7,19.1-36.3,29.2-53.7c10.4-17.9,21.2-35.4,32.6-52.8c5.7-8.7,11.5-17.2,17.4-25.7c5.1-7.4,11.9-11.8,16.9-18.4
            c8.1-10.7,14.1-23.3,24.5-31.7c4.5-3.7,9.7-6.4,14.9-9.1l10.3-5.4c1.1-0.7,2.4-1.2,3.7-1.4c1.5-0.2,3.1,0,4.5,0.6
            c12.7,4.1,18.6,20.4,19.1,32.7c0.7,17.9-11.3,29.3-18.5,44.2c-5.6,11.6-5.1,25.4-1.3,37.7c8.3,27.2,30.9,46.3,40,72.3
            c3.9,11.2,6.9,22.7,9.1,34.4c2.3,13.1,2.6,22.6,14.3,31.9c10,8,22.8,11.7,35.3,14.7c55.1,13,111.9,10.4,167.6,19.2
            c38.9,6.1,79.1,22,100.3,55.1c19.6,30.6,20.5,72,46,97.9c14.7,15,35.2,22.2,53.7,32.3c25.7,14,47.9,33.9,64.6,57.9
            c41.7,59.9,46.3,140.1,90,198.5c41.7,55.8,115.2,86.5,150.8,144"/>
        <path className="st2" d="M800.8,1081.9c-5-9.1-11-17.7-16.9-26.2c-16.7-24-33.3-48.1-50-72.1c-19.9-28.7-40.1-57.8-52.2-90.6
            c-10.6-28.6-14.8-59.1-23.5-88.3c-19.7-66.1-61.8-123.3-107-175.4c-16.8-19.5-35.3-45.9-23-68.5c17.3-31.7,61.5-10.8,86.3-2.4
            c21.8,7.4,44.7,10.8,67.5,14.2c9.6,1.5,19.2,2.9,28.3,6.1c9.8,3.4,18.7,8.8,27.6,14l20.3,12.1c5.3,3,10.4,6.4,15.2,10.1
            c7.9,6.4,14.1,14.7,20.2,22.8c9.2,12.3,17.7,26.3,30.1,35.8c4.4,3.4,9.3,6.2,13.9,9.3c40,25.8,64.5,63.5,87.9,103.8
            c8.8,15.1,14.1,31.9,19.4,48.5c8.2,26,16.5,52,24.7,78.1c11.5,36.4,24.1,74.5,52,100.5c13.9,12.9,30.8,22,46,33.4
            c13.2,9.9,25,21.6,35,34.8"/>
        <path className="st2" d="M425.8,478.3c0.4-27-13-52-24.9-76.1c-2.3-4.5-4.3-9.1-6-13.8c-6-18.6-1.8-39-5.7-58.1
            c-1.6-7.9-5.6-16.5-13.5-18.7c-5.4-1.5-11.2,0.7-15.4,4.3s-7.1,8.5-9.9,13.3c-8.5,15.1-17.6,30.3-25.7,45.6
            c-9.1,17.2-15.1,35.6-25.1,52.7c-2.3,3.9-4.9,8-7.6,12.1c-8.8,13.8-18.2,28.5-19.3,44.2c-0.2,3-0.1,6.1,1.3,8.8
            c1.8,3.5,5.6,5.5,9.2,7.3c17.3,8.6,34.8,17.3,53.6,21.7s39.4,4.2,56.7-4.4c6.9-3.4,13.1-8.1,19.2-12.7c3-2,5.7-4.5,8-7.3
            C424.7,491.7,425.7,484.9,425.8,478.3z"/>
        <path className="st2" d="M940,1081.9c-0.6-0.8-1.2-1.5-1.8-2.3c-12.2-16.8-17.2-37.7-21.9-57.9c-2.6-10.9-5.1-21.8-7.6-32.7
            c-9.2-39.2-9.5-81.6-25.1-119.3c-3.5-8.6-7.5-17.2-13.4-24.3c-6.7-8.1-18.4-14.8-22.3-24.9c-3.6-9.5-5.7-18.8-12.3-27.2
            c-5.2-6.5-12.2-11.2-18.9-16.2c-14.3-10.7-27.4-23-38.8-36.8c-4.1-4.9-8.2-10.2-14-12.9s-13.9-1.7-17,3.9c-1.7,3-1.5,6.7-1.3,10.2
            c1,17.7-0.9,38.9,6.2,55.4s7.5,35.8,10.1,53.4c0.7,4.9,1.4,9.8,2.5,14.6c2.3,10.9,2.9,23.2,7.3,33.3c5,11.5,9.4,23.1,15,34.4
            c5,10,10.5,19.8,18,28.1c6.5,7.2,14.2,13,21.3,19.7c15.4,14.6,26.8,32.9,38.1,50.9c10.2,16.3,20.5,33.1,28.4,50.6"/>
        <path className="st2" d="M871.9,909.9c-12.9-24.5-28-47.8-45.1-69.6c-6-7.7-19.8-20.1-27-10.9c-0.6,0.7-1.1,1.5-1.5,2.3
            c-5.7,11.3-6.3,24.6-4.1,36.5c2.6,13.7,7.8,26.8,13.2,39.6c6.2,14.8,13.2,30.2,26.2,39.6c5,3.7,10.7,6.3,16.4,8.8
            c3.6,1.7,7.4,3.3,11.3,3.5c10.4,0.4,18.6-10.1,19.4-20.5S876.7,919.1,871.9,909.9z"/>
        <path className="st2" d="M517.7-2.1c-7.4,12.5-14.7,25.2-21.8,37.9c-10.6,19.1-21,39.7-19.6,61.5c2,31.5,28.3,59.3,24.2,90.6
            c-1.4,10.7-6.4,20.6-10.5,30.6c-13.5,32.6-19,68-16,103.1c1.7,19.8,6.3,39.9,17.9,56c13.1,18.2,33.7,29.6,54.6,37.4
            c47.4,17.8,99,20.7,148.3,32.1s100,34.4,124.9,78.6c15.6,27.6,20,61.9,42,84.8c32.3,33.7,91.2,31,121.2,66.7
            c9.1,10.9,14.5,24.3,19.8,37.4c16,40,32.1,80.1,48.2,120.1c11.2,27.8,22.7,56.2,42.6,78.6c39.7,44.5,108.8,61.1,134.7,114.8
            c8,16.6,10.8,34.9,12,53.6"/>
        <path className="st2" d="M1666.7,1081.9c-0.4-3.7-0.8-7.4-1.2-11.1c-1.8-17-5.6-36.9-21.2-43.9c-13.5-6.1-29.7,1.2-39.7,12.1
            c-10.1,10.8-16.1,24.8-24.8,36.8c-1.6,2.1-3.2,4.2-4.8,6.2"/>
        <path className="st2" d="M581.2-2.1c-19.6,39.5-37.5,80.6-37.6,124.4c-0.1,13.8,1.6,27.5,1.5,41.2c-0.5,60.4-34.5,126.2-2.4,177.4
            c18.8,30.1,55.5,44.1,90.5,49.9s71.4,5.6,105.1,16.7c43.5,14.3,80.3,47.9,98.5,89.9c8,18.3,12.7,38.4,24.1,54.8
            c24.5,35.3,71.8,44.1,110.1,63.6c41.8,21.3,79.7,60.8,93.9,106.2c5.7,18.2,2.1,37.3,8.4,55.3c10.3,29.3,27.1,57.4,40.6,85.3
            c4.2,8.7,8.5,17.5,15.3,24.3c14.3,14.1,35.4,16,53.2,23.4c19.9,8.2,31.6,25.3,45.7,40.7c31.7,34.6,61.2,62.4,76.9,108.4
            c2.5,7.3,4.4,14.9,5.7,22.5"/>
        <path className="st2" d="M1438,1081.9c11.1-5.4,21.6-13.8,30.6-22.1c22.3-20.5,44.6-42.5,56.1-70.5c9.7-23.4,11.2-49.3,18.3-73.5
            c5.4-18.4,14-35.7,21-53.6c23.3-59.2,30-126.1,67.2-177.8c3.7-5.1,7.7-10.1,12.9-13.8c5.8-4.1,12.7-6.4,19.6-8
            c23.4-5.6,47.9-4.9,71,1.8c10,2.8,19.5,7.1,28.2,12.9c36.9,25.3,44.9,76.2,44.5,120.9c-0.1,10.4-0.5,21-4,30.8
            c-2.9,8.3-7.9,15.6-12.8,22.8c-33.5,49.7-66.4,106.2-59,165.7c2.8,22.3,11.1,43.4,20.2,64.2"/>
        <path className="st2" d="M673.1-2.1c-2.3,11.7-4.3,23.4-6.4,35.1l-33.5,186.6c-4,22.5-7.7,47.3,4.1,66.9c11.6,19.2,35.2,27.9,57.5,29.9
            s45-1.2,67.3,0.9c71.6,6.9,127.5,67.7,156.9,133.4c10.4,23.2,19.5,49,40.5,63.5c19.8,13.7,45.5,13.9,69.1,19.2
            c34.3,7.8,65.1,26.9,87.3,54.2c28.8,35.5,40.6,81.8,47.5,127c6.3,41,13.4,89,49.7,109.2c21.2,11.8,31.1,28.6,50.4,43.2
            c9.7,7.3,19.3,14.6,29.7,20.8c11.6,6.9,24.2,12.4,34.1,21.5c14.1,13,21.1,32,33.8,46.4c5.7,6.5,12.5,12.1,16.6,19.6
            c4.1,7.5,5.2,16.2,6.2,24.7c2.7,23.1,5.4,46.1,8.1,69.2c0.4,3.7,0.9,7.6,3.2,10.7c0.5,0.7,1.2,1.4,1.9,2"/>
        <path className="st2" d="M1922.1,731.4c-5.4-6.2-12.1-11.6-19-16.6c-15.3-11.3-35.6-10.8-50.8-22.6c-8.4-6.5-11.8-14.4-16.5-23.4
            c-5-9.4-11-18-15.4-27.8c-2.8-6.1-5.2-12.3-8.4-18.2c-14-26-42.7-43.5-72.2-43.8c-6.5-0.1-13,0.7-19.3,2.3
            c-10.5,2.9-20,8.7-29.1,14.6c-13,8.4-25.8,17.4-39.5,24.5c-13.1,6.8-25.9,9-39.4,14.1c-17.1,6.5-22.7,25.4-28.1,41.3
            c-7.4,21.8-12.6,43.1-14.1,66.3c-2.3,35.2-20.1,72-33,104.8c-5.7,14.5-14.4,27.1-20.8,41.3c-7.5,16.5-13.7,33.5-20.1,50.5
            c-8.2,21.7-19.9,41.9-31.7,61.9c-3.1,5.3-6.4,10.8-11.4,14.3c-12.5,8.7-30.7,1.2-39.6-11.2c-8.9-12.4-11.3-28-15.2-42.7
            c-11.3-42.2-37.5-79.9-72.3-106.1c-34.1-25.8-74.8-38-101.6-73.8c-36.7-49-27.9-118.4-52.6-174.4c-21.5-48.8-66.6-83-112.8-109.5
            c-32.3-18.6-67.9-36.1-87-68.1c-12-20.1-17.9-46.1-38.2-57.9c-6.3-3.6-13.8-5.7-18.7-11c-3.1-3.4-4.9-7.8-6.8-12
            c-14.6-31.2-44.7-54.6-78.5-61.1c-27.9-5.3-56.8,0.4-85.2-1.5s-59.7-15-68.3-42.1c-4.4-13.8-2-28.7,0.5-43
            c9.2-52.6,20.2-105.2,41.1-154.3c7-16.4,15-32.3,23.2-48.2"/>
        <path className="st2" d="M1922.1,794.8c-7.6,11.8-18.2,22-29.4,30.5c-20.9,15.6-44.8,27-64.4,44.2c-38.3,33.7-55.3,85.5-63.9,135.8
            c-0.9,5.4-1.8,11-0.6,16.3c1.8,8.2,8,14.6,13.6,20.8c10.9,12.2,20.5,25.4,28.7,39.5"/>
        <path className="st2" d="M1922.1,686.3c-10.9-2.2-21.7-4.9-31-10.8c-21.4-13.7-29.5-40.7-34.4-65.6c-5.5-28.1-9.1-56.6-10.8-85.2
            c-1.8-30.3-1.6-61.7-13.8-89.5c-12.2-27.8-41.2-51.2-71-45.5c-31.8,6.1-49.2,40.3-57.9,71.4c-8.7,31.1-14.9,65.9-39.6,86.8
            c-9.1,7.7-20,12.7-30.2,18.9c-41.1,25.1-68.3,68-86.8,112.4c-18.5,44.4-29,91.4-49.3,135.3c-6.4,14-13.4,27.7-20.4,41.4
            c-6.1,11.9-20,59.5-37.3,57.5c-7.2-0.8-11.3-8.5-14.4-15c-18.5-39-49.8-76.4-81.2-105.5c-30-27.7-81.1-23.3-100.5-65.3
            c-10.6-23-4.1-50.1-8.4-75.1c-3.2-18.7-12.3-35.7-21.3-52.3c-25.7-47.6-55-98.6-104.9-119.5c-12.6-5.2-26-8.4-38-14.9
            c-44-24.1-54.8-86.5-98.1-111.9c-12.5-7.4-28.2-12.1-34.9-24.9c-2.7-5.2-3.6-11.3-6.5-16.4c-8.1-14.5-26.7-20.4-41-26
            c-16.5-6.5-29-17.7-43.1-28.7c-3.3-2.6-6.7-5.3-10.7-6.8c-5-1.9-10.5-1.9-15.9-1.9l-48.4,0.5c-8.4,0.1-17.3,0-24.4-4.4
            c-7.3-4.5-11.3-12.8-14.9-20.6c-5.8-12.6-15.5-26.8-16.3-40.5c-0.7-13.3,5-29.9,8.3-42.7c7.5-29.2,18.5-57.4,32.9-84
            c10.9-20.2,23.8-39.8,36-59.7"/>
        <path className="st2" d="M1922.1,859.5c-2.9,2.1-6,4.1-9.1,5.8c-11.8,6.3-25.2,9.9-36,17.8c-22.4,16.6-29.3,48.8-52.3,64.6
            c-5.2,3.6-11.3,6.4-14.6,11.7c-6.6,10.5,1,24.3,10.4,32.3s21.4,13.8,27.3,24.7c2.6,4.8,3.8,10.3,4.9,15.6c3.5,16.4,7,33.2,7.7,49.9
            "/>
        <path className="st2" d="M1922.1,959.7c-14.9,13.5-32.3,25.4-47.7,37.2c-3.8,2.9-7.9,6.5-7.8,11.2c0.1,5.1,5.3,8.7,10.3,9.8
            s10.3,0.8,15.1,2.6c15.3,5.7,15.9,26.9,24.3,41c1.7,2.7,3.6,5.2,5.8,7.6"/>
        <path className="st2" d="M1922.1,634.9c-3.1-2.6-6-5.3-8.8-8.2c-19-19.7-30.7-46-33.8-73.2c-3.2-28.3-5.1-52.6,4.7-79.6
            c6.1-16.9,9.7-34.5,10.8-52.4c0.2-3.6,0.3-7.3-0.7-10.7c-1.2-4-3.8-7.3-6.3-10.6c-5.4-6.9-10.8-13.9-17.4-19.6
            c-12.9-11.2-26.4-22.2-35.3-37.1c-9.1-15.3-14.5-38.9-13.8-56.7c0.3-6.8,1.5-13.6,0.3-20.2s-5.6-13.4-12.3-14.5
            c-6.8-1.2-13.1,3.6-19,7.2c-10,6.1-21.6,7.6-31.6,13c-9.5,5.1-18.3,8.6-28.4,12.6c-30.5,12-44.5,39.7-52.1,69.7
            c-10,39.3-23.5,80.5-28.1,120.8c-1.7,15-0.8,23-11.8,35.3c-12,13.3-28.2,21.8-42.5,32.3c-40.8,30-65.6,75-84.5,121
            c-20.1,49-44.5,94.8-62.7,145.1c-1.4,3.9-2.9,7.9-5.8,10.8c-6.1,6.2-16.8,5.3-24.2,0.6s-12.3-12.3-17.4-19.3
            c-19-25.5-44-45.9-72.8-59.4c-7.3-3.4-14.9-6.4-21.7-10.9c-30.7-20.7-43.3-81-49.4-115.4c-5.9-33.5-4.5-69.1-19.5-99.6
            c-11.9-24.2-33.1-42.6-55.3-58c-23.1-16-47.7-29.5-73.6-40.3c-23-9.6-47.6-17.5-66.1-34.3c-10.6-9.7-18.6-21.8-27.1-33.4
            c-12.8-17.5-26.9-34.1-41-50.6c-27.1-31.8-57.3-65.5-98.2-73.8c-19.3-3.9-39.3-1.6-58.9-3.5c-18.2-1.8-36.8-8.3-48.5-22.9
            c-14.7-18.2-7.9-40.4-3-61.3l12.2-52.2c0.9-4,1.9-8.1,4-11.7c4.1-7.1,11.8-11,18.6-15.6c21.7-14.6,37.1-35.9,48.5-60"/>
        <path className="st2" d="M981-2.1c-8.5,49.7-61,82.7-96.2,121.7c-5.1,5.7-10.1,12.1-10.9,19.7c-1.2,12.6,10.5,23.5,22.8,26.5
            s25.3,0.4,37.9-1.1c93.4-11.3,191,44,229.3,129.9c3.9,8.6,7.3,17.7,13.7,24.7c6.6,7.2,15.8,11.5,24.5,16c22.7,11.9,43.6,27,62,44.9
            c28.3,27.5,50,60.9,67.5,96c9,18,15.8,36.8,24.1,55.1c7.8,17.3,22,31.6,28,49.6c3.6,10.7,3.6,22.2,3.6,33.5V651
            c0,24.4,21.7,31.5,36.1,12.5c16.2-21.3,19.7-49.3,35.3-70.6c20.1-27.4,45-52.3,67.1-78c4.3-5,8.5-9.9,13.6-14.1
            c8.8-7.2,19.7-11.6,28.7-18.6c49.5-38.5,30.5-123.2,34-176.4c1.4-22,6.4-44.9,18.3-63.7c10.5-16.6,28.1-25,43.6-36.4
            c16.1-11.9,37.2-17.6,55.6-25c21.2-8.6,41.3-21.9,56.4-39.1c14-15.9,31.2-27.4,39-48.3c3.9-10.4,5.5-21.6,9-32.2
            s9.2-21.1,18.7-26.8c2-1.3,4.4-2.1,6.8-2.4c2.8-0.1,5.6,0.6,8.1,1.9c13.6,6.3,19.6,18.1,24.5,31.5c4.7,12.8,7.4,25.7,12.1,38.3
            c5.5,14.8,6.6,29.9,11.9,44.8c3.8,10.6,7.6,21.6,6.7,32.8c-0.5,6.1-2.3,12-4.4,17.8c-3.9,10.9-8.5,21.5-13.7,31.8
            c-5.8,11.3-12.4,22.3-15.8,34.5s-3.2,26.2,4.1,36.6c6.3,8.9,16.7,13.9,26.7,18.4c4.1,1.9,8.3,3.6,12.4,5.4"/>
        <path className="st2" d="M1922.1,35.3l-4.2,2.4c-3.2,1.7-6.1,3.6-8.9,5.9c-14.8,12.7-11.8,27.1-6.2,43.7c5,14.6,12.2,28.5,19.3,42.4" />
        <path className="st2" d="M1804.5-2.1c-6.1,13.1-11.8,26.4-16.9,39.8c-13.3,35.3-27.2,72.8-61.3,94.1c-20.4,12.7-44.9,16.6-67.4,24.9
            c-54,20-96.6,68.2-109.8,124.2c-12.8,54.1-3.9,110.5-14.9,164.4c-1.1,5.3-2.3,10.6-4.8,15.3c-3,5.6-7.7,10-12.2,14.4
            c-27.2,26.9-49.8,58.1-66.9,92.3c-2.2,4.4-4.4,9-8.2,12.3s-9.5,4.8-13.7,2.2c-2.3-1.4-3.8-3.8-5.3-6
            c-11.1-17.5-12.7-36.1-18.5-55.9c-5.2-17.6-11.9-31.9-20.4-48.1c-3.6-6.9-6.3-14.3-9.3-21.5c-13.6-32.8-35.5-67.6-62.2-91.2
            c-25.9-22.9-59.8-36.1-81.7-63.7c-9-11.4-15.4-24.6-21.7-37.7c-3.6-7.5-6.5-16.6-12.3-22.6c-5.5-5.8-11.6-11.4-17.2-17.1
            c-24.3-24.7-62.1-60.8-97.1-67c-36-6.4-73.4-10.6-100.7-37.7c-2.3-2.3-4.5-4.8-5.7-7.8s-1.2-6.4-1-9.7
            c1.8-36.5,24.8-62.4,44.7-90.4c1.8-2.5,3.6-5,5.4-7.5"/>
        <path className="st2" d="M1759.8-2.1c-5.2,10.3-11.1,20.4-17.4,30.1c-10,15.2-17.4,29.5-31.4,41.1c-14.1,11.7-30.3,20.4-47.1,27.8
            c-21.4,9.3-43.4,22.5-65.9,28.6c-32.9,9-69.1,28.8-84.5,60.6c-16.4,34.1-14.6,74.1-7.8,111.4c4.3,24,10.3,49.5,1.1,72.1
            c-2.7,6.6-6.6,12.6-9.9,19c-10.3,20-12,42.9-21.2,63.5c-3.8,8.4-8.9,14-11.6,23.3c-1.6,5.6-4.5,11.7-10,13.6
            c-7.3,2.6-14.8-3.4-19.6-9.6c-17.8-23.2-33.8-47.7-48-73.2c-6.2-11.2-12.7-22.5-15.9-35c-3.1-11.9-4.1-24.3-8.2-36
            c-3.1-8.7-7.7-16.8-12.4-24.8c-11.3-19.4-23.4-39.7-42.8-51c-11-6.4-23.8-9.5-34.2-16.9c-8.7-6.1-12.7-15.1-21.9-21.2
            c-21-14-42.2-28.8-56.7-49.9c-13.2-19.1-21.2-43.5-41.4-55.1c-13.7-7.9-30.3-8.1-45.8-10.9c-17.5-3.2-38.1-14.6-42.7-33.3
            c-2.2-9.1-1-18.7,0.7-27.9c2.3-13,5.5-26.1,11.4-37.9c1.4-2.8,3-5.6,4.5-8.4"/>
        <path className="st2" d="M1122.7-2.1c-0.9,3-1.5,6.1-1.7,9.2c-0.6,11.9-0.6,20.8,6.8,31.1c7.8,10.8,17.8,19.8,29.4,26.2
            c4.3,2.4,8.8,4.5,13.4,6.4c17,7.2,42.8,7.7,53.7,24.4c11.9,18.4,24.7,36.1,38.3,53.1c11.4,14,22.8,28.2,30.3,44.7
            c4.4,9.7,7.4,20.2,14,28.6c16.6,21.1,48.1,22.8,70,36.2c26.2,16,40.4,51.7,39,81.8c-0.9,20.5-6.8,46.1,10.2,57.6
            c15.6,10.6,38.1-3.1,43.9-21s0.4-37.3-4.1-55.6c-15.8-64.7-16.7-141.5,30.5-188.6c49.1-48.9,134.8-49.6,177.3-104.4
            c7.1-9.1,12.5-19.2,17.5-29.6"/>
        <path className="st2" d="M1340-2.1c7.7,7.5,14,16.4,18.5,26.1c10,21.5,12.6,45.7,15.1,69.3c2.1,19.8,4.1,39.6,6.2,59.4
            c0.6,5.2,1.2,10.8,4.6,14.8c7.6,9,23.5,3.4,29.4-6.8c5.9-10.2,5.6-22.7,7.7-34.3c8.9-49.3,58.9-78.5,103.8-100.8
            c16.4-8.1,33-16.5,47.3-27.7"/>
        <path className="st2" d="M1388.4-2.1c0.1,0.2,0.1,0.3,0.2,0.5c7.5,21.3,14.9,42.7,22.4,64c0.5,1.8,1.4,3.6,2.6,5.1c2,2.1,4.8,3.2,7.7,3
            c10-0.3,16.3-10.7,20.6-19.7c8.5-17.8,16-36.8,27.4-52.8"/>
        <path className="st2" d="M322.2,1081.9c-14.8-14.4-27.8-30.6-37.1-49c-9.5-18.9-14.8-39.7-16.8-60.8c-2.1-22.4-1.5-47.9-17.9-63.4
            c-12.4-11.7-30.8-13.7-47.7-15.5c-36.9-4-74-9.9-108.1-24.4C59,853.9,28,830.2-1.9,805.4"/>
        <path className="st2" d="M251.5,1081.9c-7.4-8-13.1-17.6-17.6-27.6c-9.6-21.4-14.1-44.7-13.2-68.1c0.5-13.7,2.1-29.5-7.8-38.9
            c-4.3-4.1-10.1-6.1-15.8-8c-28.2-9.3-56.7-17.5-85.6-24.5c-17.2-4.2-34.6-8-51.2-14.3c-16.9-6.4-32.7-15.4-48.4-24.4l-13.8-7.9"/>
        <path className="st2" d="M-1.9,918.4l7.7,3.4c7.6,3.3,15.7,7.9,23.7,10.1c6.6,1.8,10.4,1.8,16.6,5.7c5,3.2,10.8,4.8,16.5,6.4
            c9.7,2.8,21.3,3.3,29.9,8.6c8,5,14.4,12.5,23.2,16.3c9,4,19.2,4.1,28.9,5.3c10.3,1.2,20.5,3.5,30.3,7c4.9,1.7,9.8,3.8,13.3,7.5
            c7.7,8.3,1.7,20.1-1.8,29c-4,10-8.6,20.4-7.3,31.4c1.6,14,12,24.4,24,32.8"/>
        <path className="st2" d="M-1.9,958.9c10.5,3,21,5.9,31,10.1c21.3,8.9,40.2,23.3,62.4,29.5c17.9,5.1,37.4,4.6,54.3,12.4
            c6.8,3.2,13.6,8.4,14.6,15.9c1.3,9.4-6.7,17.1-10.3,25.9c-3.9,9.4-2.4,19.5,1.3,29.2"/>
        <path className="st2" d="M104.9,1081.9c0.8-2.5,2.1-4.9,2.8-7.5c2.4-9.9-6.1-19.3-15.5-23.3c-11.6-4.9-24.6-4.7-37-7
            c-21.2-3.9-39.6-14.8-57.1-27.7"/>
        <path className="st2" d="M-1.9,183.8l14.3-35.4c2.6-6.5,5.4-13.2,10.8-17.7c3.8-3.2,8.6-5,12.8-7.6c8.4-5.2,14.4-13.5,16.8-23
            c1.2-4.5,1.3-9.3,0.3-13.8c-0.8-3.8-2.6-7.3-3.8-11C47.5,69.5,47,63.5,46,57.6C42.5,38.9,33.1,21.9,27,4c-0.7-2-1.3-4-1.9-6"/>
        <path className="st2" d="M-1.9,141.8l8.6-10.9c8-10.1,16.6-21.9,14.6-34.7c-0.7-4.5-2.8-8.7-4.8-12.9C10.4,70.9,4.3,58.3-1.9,45.7" />
      </g>
    </svg>
    </div>
  )
}