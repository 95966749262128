import TweenMax from 'gsap/src/uncompressed/TweenMax'
import TweenLite from 'gsap/src/uncompressed/TweenLite'
import TimelineMax from 'gsap/src/uncompressed/TimelineMax'
import ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic'
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'

export function dot() {
  const cursor = document.getElementById('cursor')
  const bg = document.getElementById('cursor-bg')
  const links = document.getElementsByTagName('a')
  // const body = document.getElementsByTagName('body')[0]

  document.addEventListener('mousemove', trailMouse, false)

  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener('mouseenter', hover, false)
    links[i].addEventListener('mouseleave', unhover, false)
    links[i].addEventListener('mouseup', unhover, false)
  }

  function trailMouse(e) {
    TweenMax.to(cursor, 0.01, { left: e.clientX, top: e.clientY })
    TweenMax.to(bg, 0.2, { left: e.clientX, top: e.clientY, ease: Power1.easeOutBack })
  }

  function hover() {
    cursor.classList.add('hover')
    bg.classList.add('hover')
  }

  function unhover() {
    cursor.classList.remove('hover')
    bg.classList.remove('hover')
  }
}

export function magic() {
  const heading = document.getElementsByClassName('big-heading')[0]
  const letters = document.getElementsByClassName('hero-letter')
  const cloud1 = document.getElementsByClassName('cloud')[0]
  const cloud2 = document.getElementsByClassName('cloud')[1]
  const cloud3 = document.getElementsByClassName('cloud')[2]

  const tl = new TimelineMax()

  function getRandom(min, max) {
    return Math.random() * (max - min) + min
  }

  tl.from(heading, 2, { y: 50, ease: Power1.easeOutBack, force3d: true })
  TweenMax.to(cloud1, 8, { y: -10, opacity: 0.6, repeat: -1, yoyo: true, ease: Power1.easeOut })
  TweenMax.to(cloud2, 7, { y: -15, opacity: 0.6, repeat: -1, yoyo: true, ease: Power1.easeOut })
  TweenMax.to(cloud3, 6, { y: -30, opacity: 0.6, repeat: -1, yoyo: true, ease: Power1.easeOut })

  function stagger(index, element) {
    tl.from(element, 2, {
      opacity: 0,
      y: getRandom(-20, -50),
      ease: Power1.easeOutBack,
      // ease: RoughEase.ease.config({ template: Power0.easeNone, strength: 2, points: getRandom(10, 20), taper: 'none', randomize: true, clamp: false }),
    }, getRandom(0.1, 1))
  }
  for (let i = 0; i < letters.length; i++) {
    stagger(i, letters[i])
  }

  tl.play()
}

export function mountains() {
  const hero = document.getElementById('hero')
  const dur = hero.offsetHeight
  const ranges = document.getElementsByClassName('range-img')
  const sky = ranges[0]
  const range1 = ranges[1]
  const range2 = ranges[2]
  const range3 = ranges[3]
  const range4 = ranges[4]
  const range5 = ranges[5]
  const clouds = document.getElementsByClassName('cloud')
  const cloud1 = clouds[0]
  const cloud2 = clouds[1]
  const cloud3 = clouds[2]
  const heading = document.getElementsByClassName('hero-text')[0]

  let controller = new ScrollMagic.Controller()

  let tween = new TimelineMax()
    .add([
      TweenMax.to(sky, 1, { yPercent: 60 }),
      TweenMax.to(range1, 1, { yPercent: 60 }), // Small BG Mountains
      TweenMax.to(range2, 1, { yPercent: 55 }), // Big Mountains
      TweenMax.to(cloud1, 1, { yPercent: 50 }),
      TweenMax.to(range3, 1, { yPercent: 50 }),
      TweenMax.to(cloud2, 1, { yPercent: 40 }),
      TweenMax.to(range4, 1, { yPercent: 30 }),
      TweenMax.to(cloud3, 1, { yPercent: 30 }),
      TweenMax.to(heading, 1, { yPercent: 50, scale: 0.5, opacity: 0.5 }),
    ])

  let scene = new ScrollMagic.Scene({
    triggerElement: '.hero-mountain',
    triggerHook: 0.01,
    duration: dur,
  })
    .setTween(tween)
    .addTo(controller)
    .addIndicators()

  function kill() {
    controller.destroy(reset)
    controller = null
    scene = null
    tween = null
  }
}

export function caseClick() {
  const links = document.getElementsByClassName('project-link')

  function animateCase() {
    event.preventDefault()
    console.log("prevented default")
  }

  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener('click', animateCase)
  }
}

export function throttle(conf) {
  const T = this
  T.fn = conf.fn
  T.d = conf.dur || 0.1
  T.toggle = 1
  T.f = function () { T.toggle = 1; T.fn() }
  T.tw = TweenLite.delayedCall(T.d, T.f).paused(true)
  T.run = function () {
    if (T.toggle) { T.toggle = 0; T.tw.restart(true) }
  }
  return T
}

export function upDown() {
  const header = document.getElementsByClassName('header')[0]
  let lastScrollPosition = 120
  setTimeout(window.onscroll = () => {
    const newScrollPosition = window.scrollY
    if (newScrollPosition < lastScrollPosition) {
      header.classList.remove('hidden')
    } else {
      header.classList.add('hidden')
    }
    lastScrollPosition = newScrollPosition
  }, 250)
}

// Smooth scrolling for Microsoft browsers.

export function microSmooth() {
  if (/Edge\/\d./i.test(navigator.userAgent) || /MSIE 10/i.test(navigator.userAgent) || /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
    console.log('Ugh...Microsoft...')
    const body = document.body
    body.classList.add('microsoft')
    body.addEventListener('mousewheel', (event) => {
      event.preventDefault()
      const wheel = event.wheelDelta
      const currentScrollPosition = window.pageYOffset
      TweenLite.to(window, 0.5, { scrollTo: currentScrollPosition - wheel })
    })
  }
}
