import React from 'react'
import { Link } from 'gatsby'

import '../css/navigation.scss'

export default class Navigation extends React.Component {
  render() {
    return (
      <div style={{ display: 'inline-block', float: 'right' }}>
        <nav className="main-nav">
          <Link to="/#about" className="hide-xs">About</Link>
          <Link to="/services" className="hide-xs">Services</Link>
          <Link to="/#start">Start A Project</Link>
        </nav>
      </div>
    )
  }
}
