import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { TransitionGroup } from 'react-transition-group'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Heading from '../components/heading'
import { Design, Identity, Web, Print, Direction } from '../images/service-icons'
import CTA from '../components/button'
import '../css/animations.scss'

export default () => (
  <Layout>
    <div className="page-container">
      <Row className="heading" middle="xs" center="xs">
        <Col xs={10}>
          <TransitionGroup>
            <Heading text="Services" />
          </TransitionGroup>
        </Col>
      </Row>
      <Row center="xs" start="md" id="web" className="service-full" middle="xs">
        <Col xs={10} md={3} className="center-xs"><Web /></Col>
        <Col xs={10} md={9}>
          <h2>Web Design & Development</h2>
          <p>It takes less than seven seconds for a first impression to be made. With traditional, brick-and-mortar storefronts becoming a thing of the past, your website is now responsible for making a good one.</p>
        </Col>
      </Row>
      <Row center="xs" start="md" id="identity" className="service-full" middle="xs">
        <Col xs={10} md={3} className="center-xs"><Identity /></Col>
        <Col xs={10} md={9}>
          <h2>Branding & Visual identity</h2>
          <p>Thoughts, words and actions largely define a person, but so too does the visual image they transmit. Your business is the same way. This is called “branding”—make certain yours is strong.</p>
        </Col>
      </Row>
      <Row center="xs" start="md" id="direction" className="service-full" middle="xs">
        <Col xs={10} md={3} className="center-xs"><Direction /></Col>
        <Col xs={10} md={9}>
          <h2>Creative Direction</h2>
          <p>Along with common sense and confidence, creativity simply can’t be taught. Is the left side of your cerebrum dominating the advertorial process? If so, allow me to lend an inventive hand.</p>
        </Col>
      </Row>
      <Row center="xs" start="md" id="design" className="service-full" middle="xs">
        <Col xs={10} md={3} className="center-xs"><Print /></Col>
        <Col xs={10} md={9}>
          <h2>Collateral & Print Design</h2>
          <p>E-mail templates, E-books, business cards, brochures and pamphlets—be it of a digital or more tangible nature, keeping company collateral crisp, clean and creative promises to impress.</p>
        </Col>
      </Row>
    </div>
  </Layout>
)
