import React from 'react'

export function Design() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="service-icon" width="52px" height="52px" viewBox="0 0 52 52">
      <circle className="stroke-light" cx="26" cy="26" r="25"/>
      <path className="st1" d="M26,4.9C14.4,4.9,4.9,14.4,4.9,26c0,11.6,9.4,21.1,21.1,21.1C26,46.2,26,6,26,4.9z"/>
      <rect x="24.4" y="7.7" className="fill-dark" width="3.2" height="33.9"/>
      <polyline className="stroke-white" points="18,36.2 8.4,26.6 18,17"/>
      <polyline className="stroke-white" points="34,17 43.6,26.6 34,36.2"/>
      <polygon className="stroke-white" points="28.5,7.6 23.5,7.6 23.5,37.1 26,44.4 28.5,37.1"/>
      <line className="stroke-white" x1="24.1" y1="12.4" x2="28" y2="12.4"/>
      <line className="stroke-white" x1="24.2" y1="36.2" x2="28" y2="36.2"/>
      <path className="stroke-dark" d="M18.7,21.9c-1,0-1.2,0.8-1.2,1.6c0,0.8,0,0.9,0,2c0,1.1-0.5,1.1-1.1,1.1c1,0,1.2,0.8,1.2,1.6c0,0.8,0,0.9,0,2c0,1.1,0.5,1.1,1.1,1.1"/>
      <path className="stroke-light" d="M33.3,31.4c1,0,1.2-0.8,1.2-1.6s0-0.9,0-2s0.5-1.1,1.1-1.1c-1,0-1.2-0.8-1.2-1.6s0-0.9,0-2s-0.5-1.1-1.1-1.1"/>
    </svg>
  )
}

export function Identity() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="service-icon" width="52px" height="52px" viewBox="0 0 52 52">
      <path className="fill-light" d="M26.1,4.9c0,0-0.1,0-0.1,0C14.4,4.9,4.9,14.4,4.9,26c0,11.6,9.4,21.1,21.1,21.1c0,0,0.1,0,0.1,0V4.9z"/>
      <path className="stroke-white" d="M25.8,43c-1.1-7.1-0.3-12.3,0.3-17.6"/>
      <path className="stroke-white" d="M20.8,26.8c0.1-0.8,0.2-1.6,0.3-2.4c0.3-2.8,2.8-4.8,5.6-4.5h0c2.8,0.3,4.9,2.9,4.6,5.7c-0.6,5.2-1,10.2,0.1,16.5"/>
      <path className="stroke-white" d="M20.2,41.9c-0.3-4,0-8.5,0.3-11.7"/>
      <path className="stroke-white" d="M31.3,16.2c-1.3-0.8-2.9-1.3-4.5-1.4h0c-5.7-0.4-10.7,3.9-11.2,9.7c-0.3,4.4-0.8,9-0.9,14.3"/>
      <path className="stroke-white" d="M36.5,39.5c-0.5-4.7-0.3-9,0-13.4c0.2-2.8-0.7-5.4-2.4-7.4"/>
      <path className="stroke-white" d="M11.2,18.8c-1,1.9-1.4,4.3-1.5,6.3c-0.1,2.5-0.1,5-0.1,7.5"/>
      <path className="stroke-white" d="M41.6,20.6C39.5,14.8,33.8,9.2,26.3,9h0c-5.6,0-9.9,2.8-13.2,6.9"/>
      <path className="stroke-white" d="M42.4,33c-0.3-2.5,0-4.9,0.1-7.4c0-0.5,0-1.4-0.1-2.1"/>
      <circle className="stroke-light" cx="26" cy="26" r="25"/>
    </svg>
  )
}

export function Web() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="service-icon" width="66.9px" height="52px" viewBox="0 0 66.9 52">
      <rect x="1" y="1" className="stroke-light" width="64.9" height="50" />
      <rect x="1" y="1" className="fill-light" width="64.9" height="4.3" />
      <rect x="7.2" y="10.8" className="stroke-white" width="8" height="4" />
      <line className="stroke-white" x1="6.9" y1="22.7" x2="29.6" y2="22.7" />
      <line className="stroke-white" x1="6.9" y1="27.6" x2="23.9" y2="27.6" />
      <line className="stroke-light" x1="55" y1="12.8" x2="59.3" y2="12.8" />
      <line className="stroke-light" x1="46.9" y1="12.8" x2="50.1" y2="12.8" />
      <line className="stroke-light" x1="35.8" y1="12.8" x2="41.2" y2="12.8" />
      <line className="stroke-white" x1="6.9" y1="32.5" x2="25.8" y2="32.5" />
      <line className="stroke-white" x1="6.9" y1="37.4" x2="28.5" y2="37.4" />
      <line className="stroke-white" x1="6.9" y1="42.4" x2="24" y2="42.4" />
      <circle className="fill-dark" cx="3.2" cy="3.2" r="1.1" />
      <circle className="fill-dark" cx="6.2" cy="3.2" r="1.1" />
      <circle className="fill-dark" cx="9.2" cy="3.2" r="1.1" />
      <rect x="41.8" y="25.5" className="fill-light" width="7.1" height="14.1" />
      <rect x="38.5" y="22.7" className="stroke-white" width="20.9" height="19.6" />
    </svg>
  )
}

export function Print() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="service-icon" width="52px" height="52px" viewBox="0 0 52 52">
      <rect className="fill-light stroke-light" x="1" y="1" width="41.3" height="50"/>
      <polygon className="fill-dark" points="41.5,44.8 41.5,50.1 21.7,50.1 21.7,1.9 41.5,1.9 41.5,9.8"/>
      <path className="fill-light" d="M9.3,45.1c-0.8,0-1.5-0.7-1.5-1.5v-15c0-0.8,0.7-1.5,1.5-1.5h38.9c0.8,0,1.5,0.7,1.5,1.5v15c0,0.8-0.7,1.5-1.5,1.5H9.3z"/>
      <path className="fill-dark" d="M48.2,28.6v15H9.3v-15H48.2 M48.2,25.6H9.3c-1.7,0-3,1.3-3,3v15c0,1.7,1.3,3,3,3h38.9c1.7,0,3-1.3,3-3v-15C51.2,27,49.9,25.6,48.2,25.6L48.2,25.6z"/>
      <rect className="stroke-light" x="9.3" y="28.6" width="38.9" height="15"/>
      <path className="fill-dark" d="M33,20.1c-0.8,0-1.5-0.7-1.5-1.5V9.2c0-0.8,0.7-1.5,1.5-1.5h15.3c0.8,0,1.5,0.7,1.5,1.5v9.4c0,0.8-0.7,1.5-1.5,1.5H33z"/>
      <path className="fill-dark" d="M48.2,9.2v9.4H33V9.2H48.2 M48.2,6.2H33c-1.7,0-3,1.3-3,3v9.4c0,1.7,1.3,3,3,3h15.3c1.7,0,3-1.3,3-3V9.2C51.2,7.5,49.9,6.2,48.2,6.2L48.2,6.2z"/>
      <rect className="stroke-light"x="33" y="9.2" width="15.3" height="9.4"/>
      <polygon className="fill-dark" points="47.4,41.1 47.4,42.9 28.3,42.9 28.3,29.3 47.4,29.3 47.4,31.8"/>
      <rect className="stroke-white" x="3" y="2.8" width="7.8" height="3.9"/>
      <rect className="stroke-white" x="11.2" y="30.2" width="7.8" height="3.9"/>
      <rect className="stroke-white" x="37.5" y="12.4" width="6.1" height="3.1"/>
    </svg>
  )
}

export function Direction() {
  return (
    <svg version="1.1" className="service-icon" width="52px" height="52px" viewBox="0 0 52 52">
      <path className="fill-light" d="M26,4.9C14.4,4.9,4.9,14.4,4.9,26c0,11.6,9.4,21.1,21.1,21.1C26,46.8,26,5.2,26,4.9z"/>
      <polygon className="fill-light" points="29.5,1 26,4.5 22.5,1 "/>
      <polygon className="fill-light" points="22.5,51 26,47.5 29.5,51 "/>
      <polygon className="fill-light" points="1,22.5 4.5,26 1,29.5 "/>
      <polygon className="fill-light" points="51,29.5 47.5,26 51,22.5	"/>
      <path className="stroke-light" d="M45.3,41.9C48.9,37.6,51,32,51,26C51,12.2,39.8,1,26,1S1,12.2,1,26s11.2,25,25,25c6.1,0,11.6-2.2,16-5.8"/>
      <path className="fill-white" d="M33,22.5c-0.9-1.4-2.1-2.6-3.5-3.5L1.8,1.1c-0.4-0.3-1,0.2-0.7,0.7L19,29.5c0.9,1.4,2.1,2.6,3.5,3.5
        l27.7,17.9c0.4,0.3,1-0.2,0.7-0.7L33,22.5z M22.2,29.8c-2.1-2.1-2.1-5.6,0-7.7c1.1-1.1,2.4-1.6,3.8-1.6c1.4,0,2.8,0.5,3.8,1.6
        c2.1,2.1,2.1,5.6,0,7.7c-1.1,1.1-2.4,1.6-3.8,1.6C24.6,31.4,23.2,30.9,22.2,29.8z M31.2,31.2c1.3-1.3,2-2.9,2.1-4.5c0,0,0,0,0,0
        l12.1,18.8L26.7,33.4c0,0,0,0,0,0C28.4,33.2,30,32.5,31.2,31.2z"/>
      <path className="fill-light" d="M26.7,33.4C26.7,33.4,26.7,33.4,26.7,33.4l18.8,12.1L31.2,31.2C30,32.5,28.4,33.2,26.7,33.4z"/>
      <path className="fill-dark" d="M22.2,29.8c-2.1-2.1-2.1-5.6,0-7.7c1.1-1.1,2.4-1.6,3.8-1.6c1.4,0,2.8,0.5,3.8,1.6c2.1,2.1,2.1,5.6,0,7.7
        c-1.1,1.1-2.4,1.6-3.8,1.6C24.6,31.4,23.2,30.9,22.2,29.8z"/>
    </svg>
  )
}